import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const Founder = (props) => {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  })
  
  return (
    <div>
      <Helmet>
        <title>{props?.data?.meta_title}</title>
        <meta name="description" content={props?.data?.meta_description} />
      </Helmet>
      <div className="fancy-feature-twentyFive position-relative mt-20">
        <div className="container">
          <div dangerouslySetInnerHTML={{ __html: (props?.data?.page_content) }} />
        </div>
      </div>
    </div>
  )
}

export default Founder


