import React, { useEffect, useState } from 'react'
import TeamMemberTwo from '../team/TeamMemberTwo'
import BannerFive from '../short-banner/BannerFive'
import NewsLetterForm from '../form/NewsLetterForm';
import { Helmet } from 'react-helmet';
import { UsaData } from '../../services/shared-service';
import PaginationComponent1 from './pagination';

const TeamUSA = (props) => {

  const [data,setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState([]);

  let pageData = ''

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  })

  useEffect(() => {
    getPaginatedData('1');

 
  },[])

  const getPaginatedData = (page) => {
    pageData = page

    UsaData(pageData).then((res) => {
      setData(res?.data)
      setTotalRecords(res?.total);
    })
  }
   
  return (
    <div>
     <Helmet>
          <title>{props?.data?.meta_title}</title>
          <meta name="description" content={props?.data?.meta_description} />
        </Helmet>
        <div className="fancy-feature-twentyFive position-relative mt-20">
          <div className="container">
            <div dangerouslySetInnerHTML={{ __html: (props?.data?.page_content) }} />
          </div>
        </div>
    <div className="team-section-two mt-110 mb-160 lg-mt-60 lg-mb-100">
      <div className="container">
        <TeamMemberTwo data={data}/>
        <div className="page-pagination-one pt-90">
                        <ul className="d-flex align-items-center style-none">
                            <li >
                                {
                                    totalRecords > 12 && <PaginationComponent1
                                        getAllData={getPaginatedData}
                                        totalRecords={totalRecords}
                                        itemsCountPerPage='12' />
                                }
                            </li>

                        </ul>
                    </div>
      </div>
    </div>
    {/* /.team-section-two */}
  </div>
  )
}

export default TeamUSA