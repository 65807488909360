

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import BannerFive from '../short-banner/BannerFive'
import NewsLetterForm from '../form/NewsLetterForm'
import PortfolioGalleryThree from '../../components/portfolio/PortfolioGalleryThree';
import { TestimonialVideoData, VideoData } from '../../services/shared-service';
import PaginationComponent1 from './pagination';


const TestimonialVideo = (props) => {
    const [videoData, setVideoData] = useState([]);
    const [totalRecords, setTotalRecords] = useState([]);

    let pageData = ''

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    })

    useEffect(() => {
        getPaginatedData('1');
    }, [])

    const getPaginatedData = (page) => {
        pageData = page
        TestimonialVideoData(pageData).then((res) => {
            setVideoData(res?.data);
            setTotalRecords(res?.total);
        })
    };

    return (
        <div>
            <Helmet>
                <title>{props?.data?.meta_title}</title>
                <meta name="description" content={props?.data?.meta_description} />
            </Helmet>
            <div className="fancy-feature-twentyFive position-relative mt-20">
                <div className="container">
                    <div dangerouslySetInnerHTML={{ __html: (props?.data?.page_content) }} />
                </div>
            </div>
            <div className="portfolio-gallery-three mt-140 mb-150 lg-mt-90 lg-mb-100">
                <div className="container">
                    <PortfolioGalleryThree data={videoData} />
                    <div className="page-pagination-one pt-90">
            <ul className="d-flex align-items-center style-none">
              <li >
                {
                  totalRecords > 12 && <PaginationComponent1
                    getAllData={getPaginatedData}
                    totalRecords={totalRecords}
                    itemsCountPerPage='12' />
                }
              </li>

            </ul>
          </div>
                </div>
            </div>
            {/* /.portfolio-gallery-three */}

            {/* /.fancy-short-banner-five */}

        </div>
    )
}

export default TestimonialVideo