import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { IMG_URL } from '../../shared/constent/img';

const BlogTwo = (props) => {
    let img = IMG_URL
    return (
        <Fragment>
            <div className="row gx-xxl-5">
                {props?.data?.map((val, i) => (
                    <div key={i} className={`col-lg-4 col-sm-6 d-flex ${val.className}`} data-aos data-aos-delay>
                        <article className="blog-meta-two mt-30">
                            <figure className="post-img m0">
                                <Link to={`blog/${val?.news_slug}`} className="w-100 d-block">
                                    <img src={`${img}${val?.banner}`} alt="" className="w-100 tran4s" style={{height:'320px'}}/>
                                    </Link>
                            </figure>
                            <div className="post-data">
                                <div className="post-tag">
                                    <Link to={`blog/${val?.news_slug}`}>{val.tags} </Link>
                                </div>
                                <Link to={`blog/${val?.news_slug}`} className="blog-title">
                                    <h5><div dangerouslySetInnerHTML={{__html: (val.news_title)}} /></h5>
                                </Link> <br/ >
                                <Link to={`blog/${val?.news_slug}`} className="read-btn tran3s">{val.news_content_short} <i className="fas fa-chevron-right"/></Link>
                            </div>
                            {/* /.post-data */}
                        </article>
                    </div>
                ))}
            </div>
        </Fragment>
    )
}

export default BlogTwo