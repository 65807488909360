import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import FancyFeature25 from '../feature/FancyFeature25';
import FeatureTwelve2 from '../feature/FeatureTwelve2';
import TestimonialOne from '../testimonial/TestimonialOne';
import BannerFive from '../short-banner/BannerFive';
import NewsLetterForm from '../form/NewsLetterForm';
import { HomeService, HomeSucessStory, Service } from '../../services/shared-service';
import { API_CONSTANTS } from '../../shared/constent/API.CONSTENT';
import HttpService from '../../services/http.service';
import AboutTwo from '../about/AboutTwo';
import PaginationComponent1 from './pagination';

const Services = (props) => {
  const [sevicedata, setServiceData] = useState([]);
  const [homeSucessStory, setHomeSucessStory] = useState([]);
  const [homePage, setHomePage] = useState([]);
   const [totalRecords, setTotalRecords] = useState([]);
  let pageData = ''
 

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  })

  useEffect(() => {    
    getPaginatedData('1');
    getHomeSucessStory();
    getHomePageData()
  }, [])


  
  const getPaginatedData = (page) => {
    pageData = page
    Service(pageData).then((res) => {
      setServiceData(res?.data)
       setTotalRecords(res?.total);
    })
  }


  const getHomeSucessStory = async () => {
    HomeSucessStory().then((res) => {
      setHomeSucessStory(res)
    })
  }

  const getHomePageData = async () => {
    let url = API_CONSTANTS.homepage;
    const menuData = await HttpService().GET(url);
    setHomePage(menuData?.data);
  };

  return (
    <Fragment>
      <div>
        <Helmet>
          <title>{props?.data?.meta_title}</title>
          <meta name="description" content={props?.data?.meta_description} />
        </Helmet>
        <div className="fancy-feature-twentyFive position-relative mt-20">
          <div className="container">
            <div dangerouslySetInnerHTML={{ __html: (props?.data?.page_content) }} />
          </div>
        </div>
        {/* helmet end */}

        <div className="fancy-feature-twentyFive position-relative">
          <div className="container">
            <FancyFeature25 data={sevicedata} />
            <div className="page-pagination-one pt-90">
            <ul className="d-flex align-items-center style-none">
              <li >
                {
                  totalRecords > 12 && <PaginationComponent1
                    getAllData={getPaginatedData}
                    totalRecords={totalRecords}
                    itemsCountPerPage='12' />
                }
              </li>

            </ul>
          </div>
          </div>
          {/* /.container */}
        </div>
        {/* /.fancy-feature-twentyFive */}
        {
          homePage.map((val, i) => (
            <>
              <div className="fancy-feature-twelve mt-200 pb-50 lg-mt-120">
                <div className="container">
                  <div className="row align-items-center align-items-xl-start">
                    <div key={i}>
                      <AboutTwo data={val} />
                    </div>
                  </div>
                </div>
                <img src="images/shape/shape_25.svg" alt="" className="shapes bg-shape" />
              </div>
              {/* /.fancy-feature-twelve */}

              <div
                className="feedback-section-four position-relative mt-130 pb-60 lg-mt-90 lg-pb-10">
                <div className="container">
                  <div className="title-style-three text-center" data-aos="fade-up">
                    <h2 className="main-title">{val?.sec5_1}</h2>

                  </div>
                  {/* /.title-style-three */}
                </div>
                {/* /.container */}
                <div className="inner-content mt-90 lg-mt-60">
                  <div className="slider-wrapper">
                    <TestimonialOne data={homeSucessStory} /> {/* /.feedback_slider_one */}
                  </div>
                  {/* /.slider-wrapper */}
                </div>
                {/* /.inner-content */}
              </div>
            </>
          ))
        }
        {/* /.feedback-section-four */}
      

      </div>
    </Fragment>
  )
}

export default Services