import React, { useEffect, useState } from 'react'
import TeamMemberTwo from '../team/TeamMemberTwo'
import BannerFive from '../short-banner/BannerFive'
import NewsLetterForm from '../form/NewsLetterForm'
import { Helmet } from 'react-helmet'
import { IndiaData } from '../../services/shared-service'

const TeamIndia = (props) => {
  const [data,setData] = useState([])

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  })

  useEffect(() => {
    IndiaData().then((res) => {
      setData(res)
    })
  },[])

  return (
    <div>
        <Helmet>
          <title>{props?.data?.meta_title}</title>
          <meta name="description" content={props?.data?.meta_description} />
        </Helmet>
        <div className="fancy-feature-twentyFive position-relative mt-20">
          <div className="container">
            <div dangerouslySetInnerHTML={{ __html: (props?.data?.page_content) }} />
          </div>
        </div>
    <div className="team-section-two mt-110 mb-160 lg-mt-60 lg-mb-100">
      <div className="container">
        <TeamMemberTwo data={data}/>
      </div>
    </div>


  </div>
  )
}

export default TeamIndia