import React, { Fragment } from 'react';
import { Routes, Route } from "react-router-dom";
import ArtificialIntelligence from '../views/home-pages/ArtificialIntelligence';
import PageComponent from '../components/page/pageComponent';
import AboutUsTwo from '../views/inner-pages/pages/about-us/AboutUsTwo';
import BlogDetailComponent from '../components/page/BlogDetailComponent';
import ServiceDetailComponent from '../components/page/ServiceDetailComponent';
import CategoryDetailComponent from '../components/page/CategoryDetailComponent';
import TeamDetailComponent from '../components/page/TeamDetailComponent';
import CompanyImage from '../components/page/CompanyImage';
import ServiceSubDetailsComponent from '../components/page/ServiceSubDetailsComponent';
import SearchComponent from '../components/page/SearchComponent';

const AppRouter = () => {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<ArtificialIntelligence />} />
        <Route path="/clients" element={<CompanyImage />} />
        <Route path="/:slug" element={< PageComponent/>} />
        <Route path="/blog/:slug" element={< BlogDetailComponent/>} /> 
        <Route path="/category/:slug" element={< CategoryDetailComponent/>} /> 
        <Route path="/search" element={< SearchComponent/>} /> 
        <Route path="/service/:slug" element={< ServiceDetailComponent/>} /> 
        <Route path="/team/:slug" element={< TeamDetailComponent/>} /> 
        {/* <Route path="/service/:slug/:id" element={< ServiceSubDetailsComponent/>} /> */}
      </Routes>
    </Fragment>
  )
}

export default AppRouter;