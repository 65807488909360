


import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ContactForm from '../form/ContactForm';

const Calendar = (props) => {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  })
  

  return (
    <div>
      <Helmet>
        <title>{props?.data?.meta_title}</title>
        <meta name="description" content={props?.data?.meta_description} />
      </Helmet>
      <div className="fancy-feature-twentyFive position-relative mt-10">
        <div className="container">
          <div className='row'>
            <div className="col-md-8 col-xs-12">
              <div dangerouslySetInnerHTML={{ __html: (props?.data?.page_content) }} />
            </div>
            <div className="col-md-4 col-xs-12">
              <div className="form-style-one">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Calendar