export const API_CONSTANTS = {
    menu: 'api/main_menu',
    homepage: 'api/home_page',
    aboutUs: 'api/about_page',
    page: 'Api/pages/abc',
    footer_menu_one: 'api/footer_menu_one',
    foote_menu_two: 'api/footer_menu_two',
    footer_menu_three : 'api/footer_menu_three',
    faqValue: 'Api/faq',
    blog: 'Api/blog_pagination?page=1',
    news: 'Api/news_pagination?page=1',
    details: 'Api/news_details/abc',
    news_category: 'Api/news/news_list',
    pod_cast: '/Api/podcast_pagination?page=1',
    casestudy: 'Api/case_study_pagination?page=1',
    casestudy_category: 'api/casestudy_category',
    home_testimonial: 'Api/testimonial',
    home_sevice: 'Api/service_home?page=1',
    home_sucess_story: 'Api/sucess_story',
    home_faq: 'Api/faq_home',
    home_blog : 'Api/blog_home?page=1',
    home_setting: 'Api/settings',
    serviceDetails: 'Api/services_details/dd',
    service_category: 'Api/services_category',
    video_data:"Api/all_videos",
    testimonial_video: 'Api/testimonial_video_pagination?page=1',
    winners_videos: 'Api/winner_video_pagination?page=1',
    event_videos : 'Api/event_video_pagination?page=1',
    event_photo: 'Api/event_photo_pagination?page=1',
    winner_photo: 'Api/Api/winner_photo_pagination?page=1',
    faq_category: 'Api/tbl_faq_category',
    investors: 'Api/Investor_team_pagination?page=1',
    USA:'Api/usa_team_pagination?page=1',
    india: 'Api/india',
    advisary: 'Api/Advisory_team_pagination?page=1',
    Partners :'Api/Partner_team_pagination?page=1',
    category: 'Api/category_list/category_id',
    service_pagination :'Api/service_pagination?page=1',
    winnerList: 'Api/winners',
    teamDetails: 'Api/team_details/abhinandan-singh',
    service_home: '/Api/service_home',
    partners_home: 'Api/partners_home',
    clientsApi: 'Api/Partner_pagination?page=1',
    blog_Category: 'Api/blog_pagination_cat/abc',
    management: 'Api/management_team_pagination?page=1',
    serach:'Api/blog_pagination_search?keywords=xyz',
    contactus: 'Api/contact_submit',
    saveNewsLetter : 'Api/saveNewsLetter',
    registration : 'Api/registration_submit',
    eventPhotocat: 'Api/event_photo_cat',
    winnerPhotoCat: 'Api/winner_photo_cat',
    newEventPhoto : 'Api/event_photo_pagination_with_cat/1?page=1',
    newWinnerPhoto: 'Api/winner_photo_pagination_with_cat/1?page=1',
    contactAddress: 'Api/contact_address'

}
